body {
  font-family: "Sora", sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-root.active
  svg
  path {
  fill: #fe6b01;
}

.input-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 50%;
  overflow: hidden;
}

.input-wrapper input[type="file"] {
  display: none;
}

.input-wrapper label {
  display: block;
  cursor: pointer;
}

.input-wrapper img {
  width: 27px;
  height: 28px;
}

/* global style  */

.otp-input-fields {
  /* background-color: red; */
  display: flex;
  justify-content: center;
}

.otp-input-fields input {
  /* background-color: red; */
  width: 30px;
  height: 10px;
}

.custom-toast {
  width: 300px; /* Set the desired width */
}
